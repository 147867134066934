import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import * as Constants from './constant';

const Home = React.lazy(() => import("./pages/Home"));
const HomeLanding = React.lazy(() => import("./pages/HomeLanding"));
const HomeLandingEmpresas = React.lazy(() => import("./pages/HomeLandingEmpresas"));
const MapaCandidatos = React.lazy(() => import("./pages/MapaCandidatos"));
const Login = React.lazy(() => import("./components/Login"));
const Logout = React.lazy(() => import("./components/Logout"));
const RegisterRol = React.lazy(() => import("./components/RegisterRol"));
const Registered = React.lazy(() => import("./pages/Registered"));
const Verified = React.lazy(() => import("./pages/Verified"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const DoResetPassword = React.lazy(() => import("./pages/DoResetPassword"));
const RegisterUserCandidato = React.lazy(() => import("./components/RegisterUserCandidato"));
const RegisterUserEmpresa = React.lazy(() => import("./components/RegisterUserEmpresa"));
const RegisterCandidato = React.lazy(() => import("./components/RegisterCandidato"));
const RegisterSede = React.lazy(() => import("./components/RegisterSede"));
//const Test = React.lazy(() => import("./components/Test"));

const Faq = React.lazy(() => import("./pages/Faq"));
const FaqCandidatos = React.lazy(() => import("./pages/FaqCandidatos"));
const FaqEmpresas = React.lazy(() => import("./pages/FaqEmpresas"));
const Aviso = React.lazy(() => import("./pages/Aviso"));
const QuienesSomos = React.lazy(() => import("./pages/QuienesSomos"));
const Cookies = React.lazy(() => import("./pages/Cookies"));
const Rgpd = React.lazy(() => import("./pages/Rgpd"));
const TermsConditions = React.lazy(() => import("./pages/TermsConditions"));
const TermsConditionsCandidato = React.lazy(() => import("./pages/TermsConditionsCandidato"));
const TermsConditionsEmpresa = React.lazy(() => import("./pages/TermsConditionsEmpresa"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const CondicionesVenta  = React.lazy(() => import('./pages/CondicionesVenta'));


const App = React.lazy(() => import("./pages/App"));
//const Test = React.lazy(() => import('./components/Test'));
const Profile = React.lazy(() => import("./components/Profile"));
const ProfileCandidato = React.lazy(() => import("./pages/ProfileCandidato"));
const ProfileFiscalCandidato = React.lazy(() => import("./pages/ProfileFiscalCandidato"));
const ProfileEmpresa = React.lazy(() => import("./pages/ProfileEmpresa"));
const ProfileSede = React.lazy(() => import("./pages/ProfileSede"));
const TusEmpresas = React.lazy(() => import('./pages/TusEmpresas'));

const UltimosCandidatos = React.lazy(() => import('./pages/UltimosCandidatos'));
const UltimasEmpresas = React.lazy(() => import('./pages/UltimasEmpresas'));
const EntrevistasCandidato = React.lazy(() => import('./pages/EntrevistasCandidato'));
const ContratosCandidato = React.lazy(() => import('./pages/ContratosCandidato'));
const ContratosCandidatoFinalizados = React.lazy(() => import('./pages/ContratosCandidatoFinalizados'));
const FavoritosCandidato = React.lazy(() => import('./pages/FavoritosCandidato'));
const EntrevistasEmpresa = React.lazy(() => import('./pages/EntrevistasEmpresa'));
const ContratosEmpresa = React.lazy(() => import('./pages/ContratosEmpresa'));
const ContratosEmpresaFinalizados = React.lazy(() => import('./pages/ContratosEmpresaFinalizados'));
const FavoritosEmpresa = React.lazy(() => import('./pages/FavoritosEmpresa'));
const BloqueadosCandidato = React.lazy(() => import('./pages/BloqueadosCandidato'));

const SearchCandidato  = React.lazy(() => import('./pages/SearchCandidato'));
const SearchEmpresa  = React.lazy(() => import('./pages/SearchEmpresa'));

const ContratoCandidato  = React.lazy(() => import('./pages/ContratoCandidato'));
const ContratoEmpresa  = React.lazy(() => import('./pages/ContratoEmpresa'));
const Contratar  = React.lazy(() => import('./pages/Contratar'));
const Contratado  = React.lazy(() => import('./pages/Contratado'));

const Planes = React.lazy(() => import('./pages/Planes'));
const PlanSingle = React.lazy(() => import('./pages/PlanSingle'));
const PlanFree = React.lazy(() => import('./pages/PlanFree'));
const FormRedsysAccept = React.lazy(() => import('./pages/FormRedsysAccept'));
const FormRedsysCancel = React.lazy(() => import('./pages/FormRedsysCancel'));
const Facturas = React.lazy(() => import('./pages/Facturas'));

const router = createBrowserRouter([
  { 
    element: <Home/>,
    children: [
      {path: Constants.PATH, element: <HomeLanding/>},
      {path: Constants.PATH_PARA_EMPRESAS, element: <HomeLandingEmpresas/>},
      {path: Constants.PATH_LOGIN, element: <Login/>},
      {path: Constants.PATH_LOGOUT, Component: Logout},
      {path: Constants.PATH_REGISTER, Component: RegisterRol},
      {path: Constants.PATH_REGISTERED, Component: Registered},
      {path: Constants.PATH_VERIFIED, Component: Verified},
      {path: Constants.PATH_REGISTER_USER_CANDIDATO, Component: RegisterUserCandidato},
      {path: Constants.PATH_REGISTER_USER_EMPRESA, Component: RegisterUserEmpresa},
      {path: Constants.PATH_RESET_PASSWORD, Component: ResetPassword},
      {path: Constants.PATH_DO_RESET_PASSWORD, Component: DoResetPassword},
      {path: Constants.PATH_FAQ, Component: Faq},
      {path: Constants.PATH_FAQ_CANDIDATOS, Component: FaqCandidatos},
      {path: Constants.PATH_FAQ_EMPRESAS, Component: FaqEmpresas},
      {path: Constants.PATH_QUIENES_SOMOS, Component: QuienesSomos},
      {path: Constants.PATH_RGPD, Component: Rgpd},
      {path: Constants.PATH_TERMS_CONDITIONS, Component: TermsConditions},
      {path: Constants.PATH_TERMS_CONDITIONS_CANDIDATO, Component: TermsConditionsCandidato},
      {path: Constants.PATH_TERMS_CONDITIONS_EMPRESA, Component: TermsConditionsEmpresa},
      {path: Constants.PATH_CONDICIONES_VENTA, Component: CondicionesVenta},
      {path: Constants.PATH_COOKIES, Component: Cookies},
      {path: Constants.PATH_AVISO, Component: Aviso},
      {path: Constants.PATH_FORGOT_PASSWORD, Component: ForgotPassword},
    ],
  },
  { 
    element: <App/>,
    children: [
      {path: Constants.PATH_APP, element: <Profile/>},
      {path: Constants.PATH_APP_REGISTER_CANDIDATO, Component: RegisterCandidato},
      {path: Constants.PATH_APP_REGISTER_SEDE, Component: RegisterSede},
      {path: Constants.PATH_APP_LOGOUT, Component: Logout},
      //{path: Constants.PATH_APP_TEST, Component: Test},
      {path: Constants.PATH_APP_ULTIMAS_EMPRESAS, element: <UltimasEmpresas/>},
      {path: Constants.PATH_APP_PROFILE_CANDIDATO, element: <ProfileCandidato/>},
      {path: Constants.PATH_APP_PROFILE_FISCAL_CANDIDATO, element: <ProfileFiscalCandidato/>},
      {path: Constants.PATH_APP_ENTREVISTAS_A_EMPRESAS, element: <EntrevistasCandidato/> },
      {path: Constants.PATH_APP_CONTRATOS_A_EMPRESAS, element: <ContratosCandidato/> },
      {path: Constants.PATH_APP_CONTRATOS_A_EMPRESAS_FINALIZADOS, element: <ContratosCandidatoFinalizados/> },
      {path: Constants.PATH_APP_EMPRESAS_FAVORITOS, element: <FavoritosCandidato/> },
      {path: Constants.PATH_APP_EMPRESAS_BLOQUEADOS, element: <BloqueadosCandidato/> },
      {path: Constants.PATH_APP_ULTIMOS_EMPLEADOS, element: <UltimosCandidatos/>},
      {path: Constants.PATH_APP_PROFILE_EMPRESA, element: <ProfileEmpresa/>},
      {path: Constants.PATH_APP_PROFILE_SEDE, element: <ProfileSede/>},
      {path: Constants.PATH_APP_ENTREVISTAS_A_CANDIDATOS, element: <EntrevistasEmpresa/> },
      {path: Constants.PATH_APP_CONTRATOS_A_CANDIDATOS, element: <ContratosEmpresa/> },
      {path: Constants.PATH_APP_CONTRATOS_A_CANDIDATOS_FINALIZADOS, element: <ContratosEmpresaFinalizados/> },
      {path: Constants.PATH_APP_CANDIDATOS_FAVORITOS, element: <FavoritosEmpresa/> },
      {path: Constants.PATH_APP_TUS_SEDES, element: <TusEmpresas/> },
      {path: Constants.PATH_APP_PLANES, element: <Planes/>},
      {path: Constants.PATH_APP_PLAN_SINGLE, element: <PlanSingle/>},
      {path: Constants.PATH_APP_PLAN_FREE, element: <PlanFree/>},
      {path: Constants.PATH_APP_FORM_REDSYS_CANCEL, element: <FormRedsysCancel/>},
      {path: Constants.PATH_APP_FORM_REDSYS_ACCEPT, element: <FormRedsysAccept/>},
      {path: Constants.PATH_APP_SEARCH_CANDIDATO, element:<SearchCandidato/>},
      {path: Constants.PATH_APP_SEARCH_EMPRESA, element:<SearchEmpresa/>},
      {path: Constants.PATH_APP_CONTRATO_CANDIDATO, element:<ContratoCandidato/>},
      {path: Constants.PATH_APP_CONTRATO_EMPRESA, element:<ContratoEmpresa/>},
      {path: Constants.PATH_APP_CONTRATAR, element:<Contratar/>},
      {path: Constants.PATH_APP_CONTRATADO, element:<Contratado/>},
      {path: Constants.PATH_APP_CONDICIONES_VENTA, Component: CondicionesVenta},
      {path: Constants.PATH_APP_RGPD, Component: Rgpd},
      {path: Constants.PATH_APP_TERMS_CONDITIONS, Component: TermsConditionsCandidato},
      {path: Constants.PATH_APP_FACTURAS, element: <Facturas/>},
      //{path: Constants.PATH_APP_TEST, element: <Test/>},

      
    ],
  },
  {element: <MapaCandidatos/>, path: Constants.PATH_MAPA_CANDIDATOS },

]);

export function Routes() {
  return <RouterProvider router={router} />;
}
